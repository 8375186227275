import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '../shared/locale-select/locale.service';
import { Language } from '../shared/locale-select/language.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  localeCode: string;

  constructor(private router: Router, private localeService: LocaleService) { }

  public navigateToPageNotFound(): void {
    this.localeService.getSelectedLanguage().subscribe((v: Language) => {
      this.localeCode = v.localeCode;
      this.router.navigate([this.localeCode, '404']);
    }).unsubscribe();
  }

  public navigateToPageTooManyRequests(): void {
    this.localeService.getSelectedLanguage().subscribe((v: Language) => {
      this.localeCode = v.localeCode;
      this.router.navigate([this.localeCode, '429']);
    }).unsubscribe();
  }
}
