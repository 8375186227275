import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CaptchaStateService } from './captcha-state.service';
import { CaptchaVerificationResponse } from './captcha-verification-response';

@Injectable({
  providedIn: 'root'
})
export class CaptchaVerificationService {

  constructor(
    private http: HttpClient,
  ) { }

  public verify(token: string): Observable<boolean> {
    return this.http.post<CaptchaVerificationResponse>(environment.captchaVerificationURL, {
      token: token
    }).pipe(
      tap(response => {
        if (response.success === false) {
          if (Array.isArray(response.errorCodes)) {
            throw new Error(response.errorCodes[0]);
          }
          throw new Error(`Unknown error: response was ${response}`);
        }
      }),
      map(response => {
        const state = response.success;
        return state;
      })
    );
  }
}
