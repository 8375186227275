<mat-toolbar color="primary" class="instacount-header">
  <button mat-icon-button class="toggleButton" (click)="onToggleSidenav()">
    <mat-icon>menu</mat-icon>
  </button>
  <h2 class="article-name">{{ articleName | async | uppercase }}</h2>
  <div class="search-bar">
    <instacount-search-bar></instacount-search-bar>
  </div>
  <div class="locale-select">
    <instacount-locale-select></instacount-locale-select>
  </div>
</mat-toolbar>
<instacount-progress-bar></instacount-progress-bar>