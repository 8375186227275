import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CaptchaStateService } from './captcha-state.service';

@Injectable()
export class CaptchaInterceptor implements HttpInterceptor {

  constructor(private captchaStateService: CaptchaStateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.reprocessingProcedures)) {
      if (this.captchaStateService.requestCount + 2 === environment.captchaCheckCount) {
        this.captchaStateService.requestCaptchaChallenge(true);
      }
      this.captchaStateService.increaseRequestCount();
    }
    return next.handle(request);
  }
}
