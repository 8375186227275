import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ArticleService } from 'src/app/article/article.service';
import { SearchBarService } from './search-bar.service';

@Component({
  selector: 'instacount-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
  @Output() searchStarted = new EventEmitter();
  searchInput = new FormControl();

  constructor(private articleService: ArticleService,
    private searchBarService: SearchBarService) { }

  setInputValue() {
    this.searchInput.setValue('');
  }

  onKeyPressSearch(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.doArticleSearch();
    }
  }

  onButtonClickSearch(): void {
    this.doArticleSearch();
  }

  private doArticleSearch(): void {
    const searchValue = this.searchInput.value;
    this.searchStarted.emit();
    this.searchBarService.setSearchValue(searchValue);
    this.articleService.setArticleCode(searchValue);
  }
}
