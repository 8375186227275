import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AAGHeaderFooterLibModule } from '@tecracer/aag-header-footer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationModule } from './navigation/navigation.module';
import { CaptchaModule } from './shared/captcha/captcha.module';
import { ErrorInterceptor } from './shared/error.interceptor';
import { HeaderModule } from './shared/header/header.module';
import { LandingPageComponent } from './shared/landing-page/landing-page.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { SidenavModule } from './shared/sidenav/sidenav.module';
import { PageTooManyRequestsComponent } from './shared/page-too-many-requests/page-too-many-requests.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, LandingPageComponent, PageTooManyRequestsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true
    }),
    HeaderModule,
    NavigationModule,
    SidenavModule,
    CaptchaModule,
    AAGHeaderFooterLibModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

