import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CaptchaInfoComponent } from './captcha-info/captcha-info.component';
import { CaptchaStateService } from './captcha-state.service';
import { NavigationService } from 'src/app/navigation/navigation.service';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CaptchaGuard implements CanActivate {

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private captchaDialog: MatDialog,
    private captchaService: CaptchaStateService
  ) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.captchaService.isCaptchaChallenge()
      .pipe(
        switchMap(challengeState => challengeState ? this.captchaDialog.open(CaptchaInfoComponent, {
          autoFocus: true,
          disableClose: true
        }).afterClosed()
          .pipe(
            tap(captchaResponse => {
              if (captchaResponse) {
                this.captchaService.requestCaptchaChallenge(false);
                this.captchaService.resetRequestCount();
                this.router.navigateByUrl(state.url);
              } else {
                this.navigation.navigateToPageTooManyRequests();
              }
            })
          ) : of(true))
      );
  }

}
