import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SearchBarService } from '../search-bar/search-bar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'instacount-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  articleName: Observable<string>;
  translate$: Subscription;
  translations: string[];
  message: string;

  constructor(private searchBarService: SearchBarService, private translate: TranslateService) {
    this.translate$ = this.translate
      .stream(['component.page-not-found.not-found-message'])
      .subscribe(translations => {
        this.translations = translations;
        this.message = this.translations['component.page-not-found.not-found-message'];
      });
  }

  ngOnInit() {
    this.articleName = this.searchBarService.getSearchValue();
  }

  ngOnDestroy() {
    this.translate$.unsubscribe();
  }
}
