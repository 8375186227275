// QAS
const serverHost = 'https://qas.backend.instacount.aesculap.de';
const api = 'api';
const version = 'v1';
const reprocessingsData = 'reprocessingsData';
const masterData = 'masterData';
const i18n = 'i18n';
const availableLocalisations = 'availableLocalisations';
const reprocessingsManualsData = 'reprocessingsManualsData';
const reprocessingsAVAManualsData = 'reprocessingsAVAManualsData';
const getEncodedImage = 'getEncodedImage?url=';
const captchaVerification = 'verifyCaptcha';
const captchaKey = '6LffdekUAAAAAOBtq3RbcPUgErFAU_wHiL22wjpv';

export const environment = {
  production: true,
  serverURL: `${serverHost}/${api}/${version}`,
  reprocessingProcedures: `${serverHost}/${api}/${version}/${reprocessingsData}`,
  articles: `${serverHost}/${api}/${version}/${masterData}`,
  availableLanguages: `${serverHost}/${api}/${version}/${i18n}/${availableLocalisations}`,
  instructionForUse: `${serverHost}/${api}/${version}/${reprocessingsManualsData}`,
  AVAInstructions: `${serverHost}/${api}/${version}/${reprocessingsAVAManualsData}`,
  base64image: `${serverHost}/${api}/${version}/${masterData}/${getEncodedImage}`,
  captchaKey: `${captchaKey}`,
  captchaVerificationURL: `${serverHost}/${api}/${version}/${captchaVerification}`,
  captchaCheckCount: 10
};
