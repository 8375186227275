import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidenavService {
    toggleSidenav = new Subject<boolean>();
    sidenavIsOpen: boolean;

    constructor() { }

    sidenavToggle() {
        this.sidenavIsOpen = !this.sidenavIsOpen;
        this.toggleSidenav.next(this.sidenavIsOpen);
    }
}
