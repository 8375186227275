import { Injectable } from '@angular/core';
import { Article } from './article.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, distinctUntilChanged, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ArticleInfo } from './components/article-info/article-info.model';
import { ImageElement, Base64Image } from '../shared/image-viewer/image.model';
import { NavigationService } from '../navigation/navigation.service';
import { ProgressBarService } from '../shared/progress-bar/progress-bar.service';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private articleCode = new BehaviorSubject<string>(undefined);
  private article = new BehaviorSubject<Article>(new Article());
  private articleInfo = new BehaviorSubject<ArticleInfo>(null);
  private articleImage = new BehaviorSubject<Base64Image>(undefined);
  private imageIsLoaded = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private progressBarService: ProgressBarService
  ) { }

  public findArticleByCode(
    articleCode: string,
    localeCode: string
  ): Observable<Article> {
    this.progressBarService.showProgressBar();
    return this.http
      .get<Article>(`${environment.articles}/${articleCode}/${localeCode}`)
      .pipe(
        catchError(err => {
          this.progressBarService.hideProgressBar();
          if (!environment.production) {
            console.log(err);
          }
          this.navigationService.navigateToPageNotFound();
          return of(err);
        }),
        tap(() => {
          this.progressBarService.hideProgressBar();
        })
      );
  }

  public setArticle(article: Article): void {
    this.article.next(article);
    const articleImageUrl: ImageElement = { src: article.mainPictureUrl };
    this.setArticleImage(articleImageUrl);
  }

  public setArticleCode(articleCode: string): void {
    this.articleCode.next(articleCode);
  }

  public getArticleCode(): Observable<string> {
    return this.articleCode.asObservable().pipe(distinctUntilChanged());
  }

  public getArticleInfo(): Observable<ArticleInfo> {
    this.article.subscribe((v: Article) => {
      const filteredArticle: ArticleInfo = {
        articleCode: v.articleCode,
        description: v.description,
        usedLanguage: v.usedLanguage,
        usedTextType: v.usedTextType,
        xrefArticleCode: v.xrefArticleCode
      };
      this.articleInfo.next(filteredArticle);
    });
    return this.articleInfo.asObservable();
  }

  public getArticleImage(): Observable<Base64Image> {
    return this.articleImage.asObservable();
  }

  public getImageLoadingState() {
    return this.imageIsLoaded.asObservable();
  }

  private setArticleImage(imgUrl: ImageElement): void {
    this.imageIsLoaded.next(false);
    const imageLink: string = imgUrl.src;
    this.http.get(`${environment.base64image}${imageLink}`, { responseType: 'text' }).pipe(
      catchError(err => {
        if (!environment.production) {
          console.log(err);
        }
        return of(err);
      }),
      map(res => {
        return res;
      })).subscribe(base64string => {
        const image: Base64Image = { src: 'data:image/jpeg;base64,' + base64string };
        this.articleImage.next(image);
        this.imageIsLoaded.next(true);
      });
  }
}
