<div class="container">
  <div class="header">
    <instacount-header></instacount-header>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <instacount-navigation hidden></instacount-navigation>
  <instacount-sidenav></instacount-sidenav>
</div>
<aag-bbraun-footer [defaultLanguage]="defaultHeaderLanguage"
  [language$]="language$"
  class="footer"></aag-bbraun-footer>