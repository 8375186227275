<div *ngIf="sidenav.opened" class="backdrop" (click)="sidenav.close()" ></div>
<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" [fixedInViewport]="true" class="sidenav">
        <button mat-icon-button class="button__close" (click)="sidenav.close()">
            <mat-icon>close</mat-icon>
        </button>
        <instacount-locale-select class="mobile-locale-select"></instacount-locale-select>
        <instacount-search-bar class="mobile-search-bar" (searchStarted)="sidenav.close()" ></instacount-search-bar>
    </mat-sidenav>
    <mat-sidenav-content>
    </mat-sidenav-content>
</mat-sidenav-container>