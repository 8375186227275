import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SidenavService } from './sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Component({
  selector: 'instacount-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, { static: true }) sidenavContainer: MatSidenav;
  private toggleSidenavSubscription$ = new Subscription();

  constructor(private sidenavService: SidenavService) {}

  ngOnInit() {
    this.toggleSidenavSubscription$ = this.sidenavService.toggleSidenav.subscribe(
      () => {
        this.sidenavContainer.toggle();
      }
    );
  }

  ngOnDestroy() {
    this.toggleSidenavSubscription$.unsubscribe();
  }
}
