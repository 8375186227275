<mat-form-field class="search-field" floatLabel="never">
  <input
    matInput
    type="text"
    [formControl]="searchInput"
    (keydown)="onKeyPressSearch($event)"
    autoFocus
  />
  <mat-placeholder class="placeholder">{{ 'component.search-bar.input-placeholder' | translate }}</mat-placeholder>
  <button
    *ngIf="searchInput.value"
    matSuffix
    aria-label="Clear"
    class="mat-button mat-icon-button"
    (click)="setInputValue()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <button
    matSuffix
    aria-label="Search"
    class="mat-button mat-icon-button"
    (click)="onButtonClickSearch()"
  >
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>
