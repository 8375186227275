import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'instacount-page-too-many-requests',
  templateUrl: './page-too-many-requests.component.html',
  styleUrls: ['./page-too-many-requests.component.scss']
})
export class PageTooManyRequestsComponent implements OnDestroy {

  translate$: Subscription;
  translations: string[];
  message: string;

  constructor(private translate: TranslateService) {
    this.translate$ = this.translate
      .stream(['component.page-too-many-requests.too-many-requests-message'])
      .subscribe(translations => {
        this.translations = translations;
        this.message = this.translations['component.page-too-many-requests.too-many-requests-message'];
      });
  }

  ngOnDestroy(): void {
    this.translate$.unsubscribe();
  }

}
