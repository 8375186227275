import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { LandingPageComponent } from './shared/landing-page/landing-page.component';
import { ArticleResolver } from './article/article.resolver';
import { CaptchaGuard } from './shared/captcha/captcha.guard';
import { CaptchaStateService } from './shared/captcha/captcha-state.service';
import { PageTooManyRequestsComponent } from './shared/page-too-many-requests/page-too-many-requests.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    component: LandingPageComponent,
    pathMatch: 'full'
  },
  {
    path: ':locale/404',
    component: PageNotFoundComponent
  },
  {
    path: ':locale/429',
    component: PageTooManyRequestsComponent
  },
  {
    path: ':locale',
    component: LandingPageComponent
  },
  {
    path: ':locale/:id',
    canActivate: [CaptchaGuard],
    loadChildren: () => import('./article/containers/article-dashboard/article-dashboard.module').then(m => m.ArticleDashboardModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
