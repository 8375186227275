import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { CaptchaInfoComponent } from './captcha-info/captcha-info.component';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CaptchaInterceptor } from './captcha.interceptor';
import { NgxWebstorageModule } from 'ngx-webstorage';

@NgModule({
  declarations: [
    CaptchaInfoComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RecaptchaModule,
    MatDialogModule,
    NgxWebstorageModule.forRoot({ prefix: 'instacount', separator: '.', caseSensitive: true }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaptchaInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.captchaKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'en'
    },
  ]
})
export class CaptchaModule { }
