import { locales } from './locales';

export class Language {
  localeCode: string;
  languageName: string;
  languageCode: string;
  isGlobal = false;
  globalLanguageCode = 'en';
  globalCode = '01';

  constructor(localeCode: string, language?: string) {
    this.localeCode = localeCode !== undefined ? localeCode : '01_en';
    this.languageCode = this.getLanguageCode(this.localeCode);
    this.isGlobal = (this.languageCode === this.globalCode);
    this.languageName = language ? language : this.setLanguageName();
  }

  public equals(language: Language): boolean {
    return (this.getLanguageIsoCode() === language.getLanguageIsoCode());
  }

  public toString(): string {
    return this.localeCode;
  }

  public getLanguageIsoCode(): string {
    return (this.isGlobal) ? this.globalLanguageCode : this.languageCode;
  }

  private setLanguageName(): string | undefined {
    const foundLocale = locales.find(v => this.getLanguageCode(v.localeCode) === this.languageCode);
    if (foundLocale) {
      return foundLocale.language;
    }
  }

  private getLanguageCode(localeCode: string): string {
    let languageCode: string = localeCode.substr(0, 2).toLowerCase();
    languageCode = languageCode === this.globalCode ? this.globalLanguageCode : languageCode;
    return languageCode;
  }
}
