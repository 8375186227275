export const locales = [
  { localeCode: 'de_de', language: 'Deutsch' },
  { localeCode: '01_en', language: 'English' },
  { localeCode: 'nl_nl', language: 'Nederlands' },
  { localeCode: 'fr_fr', language: 'Francais' },
  { localeCode: 'it_it', language: 'Italiano' },
  { localeCode: 'es_es', language: 'Espanol' },
  { localeCode: 'ja_jp', language: 'Japanese' },
  { localeCode: 'pl_pl', language: 'Polski' },
  { localeCode: 'pt_pt', language: 'Portuguese' },
  { localeCode: 'cs_cz', language: 'Czech' },
];
