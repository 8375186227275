import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import { LocaleService } from '../shared/locale-select/locale.service';
import { filter } from 'rxjs/operators';
import { ArticleService } from '../article/article.service';
import { Subscription } from 'rxjs';
import { Language } from '../shared/locale-select/language.model';

@Component({
  selector: 'instacount-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, OnDestroy {
  localeCode = undefined;
  articleCode = undefined;
  hasAppliedUrlParams = false;
  routerEvents$ = new Subscription();
  localeCode$ = new Subscription();
  articleCode$ = new Subscription();

  constructor(
    private router: Router,
    private localeService: LocaleService,
    private articleService: ArticleService
  ) {}

  ngOnInit() {
    this.applyRouterParams();
    this.subscribeToArticleChanges();
    this.subscribeToLocaleChanges();
  }

  private subscribeToLocaleChanges(): void {
    this.localeCode$ = this.localeService.getSelectedLanguage().subscribe((v: Language) => {
      this.localeCode = v.localeCode;
      if (this.articleCode) {
        this.navigate();
      } else {
        if (this.hasAppliedUrlParams) {
          this.router.navigate([this.localeCode]);
        }
      }
    });
  }

  private subscribeToArticleChanges(): void {
    this.articleCode$ = this.articleService.getArticleCode().subscribe((v: string) => {
      this.articleCode = v;
      this.navigate();
    });
  }

  private applyRouterParams(): void {
    this.routerEvents$ = this.router.events
      .pipe(filter(e => e instanceof ActivationStart))
      .subscribe((e: ActivationStart) => {
        const params = e.snapshot.params;
        const language = new Language(params['locale']);
        this.localeService.setSelectedLanguage(language);
        this.articleService.setArticleCode(params['id']);
        this.hasAppliedUrlParams = true;
      });
  }

  private navigate() {
    if (this.articleCode) {
      this.router.navigate([this.localeCode, this.articleCode]);
    }
  }

  ngOnDestroy() {
    this.routerEvents$.unsubscribe();
    this.localeCode$.unsubscribe();
    this.articleCode$.unsubscribe();
  }
}
