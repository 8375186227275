import { Component, OnInit } from '@angular/core';
import { ProgressBarService } from './progress-bar.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'instacount-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  isProgressBar: Observable<boolean>;

  constructor(private progressBarService: ProgressBarService) { }

  ngOnInit() {
    this.isProgressBar = this.progressBarService.getProgressBarState();
  }

}
