import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { RouterModule } from '@angular/router';
import { LocaleSelectModule } from '../locale-select/locale-select.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SearchBarModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    LocaleSelectModule,
    MatToolbarModule,
    ProgressBarModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
