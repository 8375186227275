import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language as HeaderLanguage } from '@tecracer/aag-header-footer';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Language } from './shared/locale-select/language.model';
import { LocaleService } from './shared/locale-select/locale.service';

@Component({
  selector: 'instacount-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'instacount';

  private language = new Subject<HeaderLanguage>();

  public get language$(): Observable<HeaderLanguage> {
    return this.language.asObservable();
  }

  public get defaultHeaderLanguage(): HeaderLanguage {
    const lang = this.localeService.getDefaultLanguage();
    return { name: lang.languageName, locale: lang.localeCode };
  }

  constructor(
    private translate: TranslateService,
    private localeService: LocaleService
  ) {
    const defaultLanguage = this.localeService.getDefaultLanguage();
    this.translate.setDefaultLang(defaultLanguage.getLanguageIsoCode());
    this.localeService.getSelectedLanguage().pipe(
      tap((lang: Language) => {
        this.translate.use(lang.getLanguageIsoCode());
        this.language.next({ name: lang.languageName, locale: lang.localeCode });
      }),
    ).subscribe();
  }
}
