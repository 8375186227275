import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RecaptchaComponent } from 'ng-recaptcha';
import { throwError } from 'rxjs';
import { catchError, take, tap, debounceTime } from 'rxjs/operators';
import { CaptchaVerificationService } from '../captcha-verification.service';
import { CaptchaStateService } from '../captcha-state.service';

@Component({
  selector: 'instacount-captcha-info',
  templateUrl: './captcha-info.component.html',
  styleUrls: ['./captcha-info.component.scss']
})
export class CaptchaInfoComponent {

  @ViewChild('captchaRef') public captchRef: RecaptchaComponent;

  constructor(
    private dialogRef: MatDialogRef<CaptchaInfoComponent>,
    private captchaValidation: CaptchaVerificationService
  ) { }

  public resolved(response: string) {
    this.captchaValidation.verify(response)
      .pipe(
        take(1),
        catchError((error) => {
          this.dialogRef.close(false);
          return throwError(error);
        }),
        tap(() => {
          this.dialogRef.close(true);
        })
      )
      .subscribe();
  }

}
