import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private state = new BehaviorSubject<boolean>(false);

  constructor() { }

  public getProgressBarState(): Observable<boolean> {
    return this.state.asObservable();
  }

  public showProgressBar(): void {
    this.state.next(true);
  }

  public hideProgressBar(): void {
    this.state.next(false);
  }

}
