import { Component, OnInit } from '@angular/core';
import { SidenavService } from 'src/app/shared/sidenav/sidenav.service';
import { ArticleService } from 'src/app/article/article.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'instacount-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  articleName: Observable<string>;

  constructor(private sidenavService: SidenavService,
              private articleService: ArticleService) { }

  ngOnInit() {
    this.articleName = this.articleService.getArticleCode();
  }

  onToggleSidenav() {
    this.sidenavService.sidenavToggle();
  }

}
