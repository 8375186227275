import { Component, OnInit, OnDestroy } from '@angular/core';
import { Language } from './language.model';
import { LocaleService } from './locale.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'instacount-locale-select',
  templateUrl: './locale-select.component.html',
  styleUrls: ['./locale-select.component.scss']
})
export class LocaleSelectComponent implements OnInit, OnDestroy {
  selectedLanguageCode = '';
  languageOptions: Language[] = [];
  private languageOptions$ = new Subscription();
  private selectedLanguageCode$ = new Subscription();

  constructor(private localeService: LocaleService) {}

  ngOnInit() {
    this.setSelectedLanguage();
    this.setLanguageOptions();
  }

  private setLanguageOptions(): void {
    this.languageOptions$ = this.localeService.getLanguageOptions().subscribe((v: Language[]) => {
      this.languageOptions = v;
    });
  }

  private setSelectedLanguage(): void {
    this.selectedLanguageCode$ = this.localeService.getSelectedLanguage().subscribe((v: Language) => {
      this.selectedLanguageCode = v.localeCode;
    });
  }

  onSelectLanguage(languageCode: string): void {
    this.localeService.setSelectedLanguage(new Language(languageCode));
  }

  ngOnDestroy() {
    this.languageOptions$.unsubscribe();
    this.selectedLanguageCode$.unsubscribe();
  }
}
