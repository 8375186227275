import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CaptchaStateService {

  @LocalStorage('captchaState', false)
  private captchaState: boolean;

  @LocalStorage('requestCount', 1)
  private captchaRequestCount: number;

  private captchaChallengeState$ = new BehaviorSubject<boolean>(this.captchaState);

  public get requestCount() {
    return this.captchaRequestCount;
  }

  public isCaptchaChallenge() {
    return this.captchaChallengeState$.asObservable();
  }

  public increaseRequestCount() {
    this.captchaRequestCount++;
  }

  public resetRequestCount() {
    this.captchaRequestCount = 0;
  }

  public requestCaptchaChallenge(state: boolean) {
    this.captchaState = state;
    this.captchaChallengeState$.next(this.captchaState);
  }

}
