import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Language } from './language.model';
import { environment } from 'src/environments/environment';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { LanguageDto } from './language-dto.model';
import { locales } from './locales';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private defaultLanguage: Language = new Language('01_en', 'English');
  private selectedLanguage = new BehaviorSubject<Language>(this.defaultLanguage);
  private languages = new BehaviorSubject<Language[]>(this.getLanguages());

  constructor(private http: HttpClient) {}

  public getLanguageOptions(): Observable<Language[]> {
    this.http
      .get<LanguageDto[]>(`${environment.availableLanguages}`)
      .pipe(map(res => res))
      .subscribe((v: LanguageDto[]) => {
        const langs: Language[] = v.map((d: LanguageDto) => new Language(d.localeCode, d.language));
        this.languages.next(langs);
      });
    return this.languages.asObservable();
  }

  public setSelectedLanguage(newLanguage: Language): void {
    if (newLanguage) {
      this.selectedLanguage.next(newLanguage);
    }
  }

  public getSelectedLanguage(): Observable<Language> {
    return this.selectedLanguage.asObservable().pipe(distinctUntilChanged((a, b) => a.equals(b)));
  }

  public getDefaultLanguage(): Language {
    return this.defaultLanguage;
  }

  public getDefaultLocale(): Language {
    return this.defaultLanguage;
  }

  public getLanguages() {
    return locales.map(v => new Language(v.localeCode, v.language));
  }
}
