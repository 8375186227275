import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchBarService {
  searchValue = new BehaviorSubject('');

  constructor() { }

  public setSearchValue(value: string) {
    this.searchValue.next(value);
  }

  public getSearchValue(): Observable<string> {
    return this.searchValue.asObservable();
  }
}
